import React from 'react'
import Layout from '../components/layout'
import { MainContainer, ReferenceSection, SlideH3, SlideTextSmall} from '../styles/common.styled'
import Slider from 'react-slick'
import { Slide } from '../styles/header.styled'
import { graphql } from 'gatsby' 
import SEO from '../components/seo'


export const query = graphql`
    query getReference($slug: String!) {
        sanityWorkReference(slug: {current: {eq: $slug}}) {
            _id
            heading
            text
            images {
                asset {
                    url
                }
            }
            seo {
                description,
                image {
                    asset {
                        url
                    }
                }
            }
        }
    }
`

const WorkFieldTemplate = ({data}) => {

    let referenceSettings = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        fade: true,
        cssEase: 'linear',    
        arrows: true 
      };
    

    const {heading, images, text, seo} = data.sanityWorkReference
    return (
        <Layout>
            <SEO title={heading} seo={seo} />
            <ReferenceSection>
            <MainContainer>
            <SlideH3>{heading}</SlideH3>
                    <SlideTextSmall>{text}</SlideTextSmall>
            <Slider {...referenceSettings}>
                {images.map((image, _id) => (
                    <Slide key={_id} image={image.asset.url} />
                ))}
            </Slider>
            </MainContainer>

            </ReferenceSection>
        </Layout>
    )
}

export default WorkFieldTemplate